var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-page"},[_c('header',{staticClass:"primary-header"},[_c('div',{staticClass:"heading-and-cta"},[_c('h1',[_vm._v("For all the real estate listings, sold data, and expert advice, "),(_vm.viewer || _vm.isAppPlatform)?_c('span',[_vm._v("get the app")]):_c('span',[_vm._v("sign in")]),(_vm.agent_first_name)?_c('span',[_vm._v(" with "+_vm._s(_vm.agent_first_name))]):_vm._e(),_vm._v(".")]),_vm._v(" "),(!_vm.viewer)?_c('client-only',[_c('div',{staticClass:"preregister"},[_c('get-app-link',{attrs:{"value":_vm.target},scopedSlots:_vm._u([{key:"default",fn:function({click, href}){return [_c('h2',[_vm._v("Enter your mobile phone number:")]),_vm._v(" "),_c('div',{staticClass:"field"},[_c('phone-number',{staticClass:"phone-number",attrs:{"countryCode":"CA","autocomplete":"tel","placeholder":"(416) 555 2345"},on:{"enter":function($event){{
										click()
										_vm.onPreregister(href)
									}}},model:{value:(_vm.phoneOutput),callback:function ($$v) {_vm.phoneOutput=$$v},expression:"phoneOutput"}})],1)]}}],null,false,816811742)})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"download-link-holder"},[_c('client-only',[_c('get-app-link',{attrs:{"value":_vm.target},scopedSlots:_vm._u([{key:"default",fn:function({click, href}){return [_c('a',{staticClass:"download-link rel-button-flat",class:{disabled: !(_vm.viewer || _vm.phoneOutputIsValid)},attrs:{"href":href},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();{
									click()
									_vm.onPreregister(href)
								}}}},[_vm._v("Get the App")]),_vm._v(" "),(_vm.viewer && _vm.canShare)?_c('a',{staticClass:"rel-button-flat share share-this-app",attrs:{"href":_vm.shareHref,"title":"Share the app"},on:{"click":_vm.shareApp}},[_vm._v("Share")]):_vm._e()]}},{key:"fallback",fn:function(){return [(_vm.viewer)?[_c('nuxt-link',{staticClass:"download-link rel-button-flat",attrs:{"to":{
										path: '/get-app',
										query: {
											'presented-by': _vm.sessionAgent && _vm.sessionAgent.oid || undefined,
										},
									}}},[_vm._v("Get the App")]),_vm._v(" "),_c('nuxt-link',{staticClass:"rel-button-flat search-listings",attrs:{"to":"/listings"}},[_vm._v("Search listings")])]:_c('nuxt-link',{staticClass:"download-link rel-button-flat",attrs:{"to":"/login"}},[_vm._v("Sign in")])]},proxy:true}])}),_vm._v(" "),_c('template',{slot:"placeholder"},[(_vm.viewer || _vm.isAppPlatform)?_c('nuxt-link',{staticClass:"download-link rel-button-flat",attrs:{"to":{
								path: '/get-app',
								query: {
									'presented-by': _vm.sessionAgent && _vm.sessionAgent.oid || undefined,
								},
							}}},[_vm._v("Get the App")]):_c('nuxt-link',{staticClass:"download-link rel-button-flat",attrs:{"to":"/login"}},[_vm._v("Sign in")]),_vm._v(" "),(_vm.viewer && !_vm.isAppPlatform)?_c('nuxt-link',{staticClass:"rel-button-flat search-listings",attrs:{"to":"/listings"}},[_vm._v("Search listings")]):_vm._e()],1)],2)],1)],1),_vm._v(" "),(_vm.sessionAgent)?_c('div',{staticClass:"agent-holder"},[_c('div',{staticClass:"agent",class:{[`system_${_vm.agentSystemName}`]: !!_vm.agentSystemName}},[_c('div',{staticClass:"graphic"},[_c('nuxt-link',{staticClass:"agent-image",attrs:{"to":`/agents/${_vm.sessionAgent.oid}`,"exact":"","no-prefetch":""}},[(_vm.sessionAgent.picture)?_c('img',{attrs:{"src":_vm.sessionAgent.picture.uri,"alt":`${_vm.sessionAgent.name}'s profile photo`}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"device",attrs:{"alt":"A listing displayed in the Listed app on an iPhone"}})],1),_vm._v(" "),_c('h4',[_c('span',[_vm._v(_vm._s(_vm.sessionAgent.name)),(_vm.sessionAgent.position)?_c('span',[_vm._v(", "+_vm._s(_vm.sessionAgent.position))]):_vm._e(),_vm._v(".")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.sessionAgent.office.name.replace(/\s(?=[^\s]*$)/g, " ")))])])])]):_vm._e()]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"qr-holder"},[_c('qr-code',{staticClass:"qr-code-image",attrs:{"value":_vm.shareHref,"width":"128","height":"128"}})],1),_vm._v(" "),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"features"},[_c('article',{staticClass:"feature browse"},[_c('h2',[_vm._v("Browse")]),_vm._v(" "),_c('p',[_vm._v("Browse through listings by swiping. See what's for sale or rent that's right in front of you. And search in your favourite neighbourhoods by drawing on the map.")])]),_vm._v(" "),_c('article',{staticClass:"feature alerts"},[_c('h2',[_vm._v("Alerts")]),_vm._v(" "),_c('p',[_vm._v("Save listings you really like to get alerted to price and status changes.")]),_vm._v(" "),_c('p',[_vm._v("Push notifications plus email and SMS alerts mean you never miss an update on a listing.")])]),_vm._v(" "),_c('article',{staticClass:"feature likes"},[_c('h2',[_vm._v("Likes and Lists")]),_vm._v(" "),_c('p',[_vm._v("Organize related properties into lists. Lists are great for planning open house outings, bookmarking your favourite interiors, or saving homes you’ve bought and sold.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('div',{staticClass:"disclaimer",attrs:{"data-nosnippet":""}},[_c('p',[_vm._v("Not intended to solicit properties currently listed or clients currently under contract with a brokerage.")])])])
}]

export { render, staticRenderFns }