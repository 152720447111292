
import { Component, Vue, Prop } from "vue-property-decorator";
import ClazyLoad from "@/components/universal-clazy-load.vue";

@Component<QRCode>({
	components: {
		ClazyLoad,
	},
})
export default class QRCode extends Vue {
	@Prop({required: true, type: String})
	readonly value!: string;

	@Prop({required: false, type: String, default: "auto"})
	readonly width!: string;

	@Prop({required: false, type: String, default: "auto"})
	readonly height!: string;

	get src() {
		const {HOST_WEB} = this.$config;

		const targetUrl = new URL(this.value);

		// add a share_method query string parameter to the target URL
		targetUrl.searchParams.set("share_method", "qr");

		const url = new URL(`qr_code`, HOST_WEB);

		url.searchParams.set("value", targetUrl.toString());

		return url.toString();
	}
}
