
/// <reference path="../declarations/mapkit/index.d.ts" />
import { Component, Vue } from "vue-property-decorator";
import RelButton from "../components/button.vue";
import { Getter, State } from "vuex-class";
import { RootState } from "../store";
import GetAppLink from "~/components/get-app-link.vue";
import QrCode from "@/components/qr-code.vue";
import PhoneNumber from "@/components/phonenumber.vue";
import { NavigatorShare } from "../misc/interfaces";
// tslint:disable-next-line:no-implicit-dependencies
import { MetaInfo } from "vue-meta";
import { ActorEventType } from "~/gql-typings/globalTypes";
import parsePhoneNumberFromString from "libphonenumber-js";
import PreregisterWithPhoneMutation from "@/apollo/PreregisterWithPhoneMutation.graphql";

@Component<HomePage>({
	components: {
		RelButton,
		GetAppLink,
		QrCode,
		PhoneNumber,
	},

	async asyncData(context) {
		const {query, store: _store, redirect, $config, req} = context;
		const $matomo = (context as any).$matomo;

		const storeState = _store.state as RootState;
		const storeGetters = _store.getters;
		const assignedAgent = storeState.assignedAgent;
		const presentedById = query["presented-by"];
		const performAppDownloadRedirect = query["app-download-redirect"] === "1" || false;

		// if agent no longer exists for this landing page, redirect to their agent page (which will resolve to a 404 page)
		if (process.server && assignedAgent && presentedById && assignedAgent.oid !== presentedById) {
			return redirect(307, `/agents/${presentedById}`);
		}

		if (performAppDownloadRedirect && $config.APP_DOWNLOAD_LINK_ANDROID) {
			// if on Android, redirect straight to Play Store
			if (
				process.server &&
				/Android/i.test(req.headers["user-agent"] || "")
			) {
				const hostname = new URL($config.HOST_WEB).hostname;

				const referrerSearchParams = new URLSearchParams();

				referrerSearchParams.set("utm_source", hostname);
				referrerSearchParams.set("utm_medium", "get-app");

				if (storeGetters.agent) {
					const campaignEncoded = encodeURIComponent(`presented-by=${storeGetters.agent.oid}`);
					referrerSearchParams.set("utm_campaign", campaignEncoded);
				}

				const baseDownloadURLAsURL = new URL($config.APP_DOWNLOAD_LINK_ANDROID);

				baseDownloadURLAsURL.searchParams.set("referrer", referrerSearchParams.toString())

				const downloadUrl = baseDownloadURLAsURL.toString();

				// Note: this won't register as an outlink in SSR contexts
				$matomo?.trackLink(downloadUrl, "link");

				return redirect(302, downloadUrl);
			}
		}

		if (performAppDownloadRedirect && $config.APP_DOWNLOAD_LINK_IOS) {
			if (
				// if obviously on iOS, redirect straight to App Store
				process.server &&
				/iPad|iPhone|iPod/i.test(req.headers["user-agent"] || "")
			) {
				const baseDownloadURLAsURL = new URL($config.APP_DOWNLOAD_LINK_IOS);

				baseDownloadURLAsURL.searchParams.set("pt", "118470456");
				baseDownloadURLAsURL.searchParams.set("mt", "8"); // unecessary, but we'll add it

				if (storeGetters.agent) {
					baseDownloadURLAsURL.searchParams.set("ct", storeGetters.agent.oid);
				}

				const downloadUrl = baseDownloadURLAsURL.toString()

				// Note: this won't register as an outlink in SSR contexts
				$matomo?.trackLink(downloadUrl, "link");

				return redirect(302, downloadUrl);
			}
		}

		return {
			performAppDownloadRedirect,
		}
	},

	head(this: HomePage) {
		const {sessionAgent} = this;
		const meta: MetaInfo["meta"] = [];

		let title = "Search for properties on Listed";

		if (sessionAgent) {
			title = `Search for properties on Listed with ${sessionAgent.name}`;
		}

		meta.push({
			hid: "og:title",
			property: "og:title",
			content: title,
		});

		const description = `For people who love real estate. The app is the easiest way to search faster, never miss an important update, and share what you love.`;

		meta.push({
			hid: "og:description",
			property: "og:description",
			content: description,
		});

		meta.push({
			hid: "description",
			name: "description",
			content: description,
		});

		// absolute URL, not a relative one, for Twitter's sake
		const ogImageUrl = new URL(require("~/assets/share_get-app_default_black.png"), this.$config.HOST_WEB);

		meta.push({
			hid: "og:image",
			property: "og:image",
			content: ogImageUrl.toString(),
		});

		meta.push({
			hid: "og:image:width",
			property: "og:image:width",
			content: "1280",
		});

		meta.push({
			hid: "og:image:height",
			property: "og:image:height",
			content: "720",
		});

		meta.push({
			hid: "twitter:card",
			name: "twitter:card",
			content: "summary"
		});

		return {
			title,
			titleTemplate(titleChunk) {
				return titleChunk;
			},
			meta,
			link: [
				{
					rel: "canonical",
					href: this.canonicalHref,
				}
			]
		};
	},
})
export default class HomePage extends Vue {
	@State("viewer") readonly viewer!: RootState["viewer"];
	@Getter("agent") readonly sessionAgent!: RootState["assignedAgent"];

	@State("canShare") canShare!: boolean;

	@State("browsingSessionId")
	browsingSessionId!: string | null;

	phoneOutput: string = "";

	performAppDownloadRedirect!: boolean;

	get agent_first_name(): string | null {
		const {sessionAgent} = this;

		return sessionAgent?.name.split(" ").find(n => n) ?? null;
	}

	get target(): string {
		const {HOST_WEB} = this.$config;

		if (process.client && !window.URL) {
			return HOST_WEB;
		}

		const url = new URL(this.$route.fullPath || "/", HOST_WEB);

		if (this.sessionAgent) {
			url.searchParams.set("presented-by", this.sessionAgent.oid);

			const campaignEncoded = encodeURIComponent(`presented-by=${this.sessionAgent.oid}`);

			url.searchParams.set("utm_campaign", campaignEncoded);
		}

		if (this.browsingSessionId) {
			url.searchParams.set("session-id", this.browsingSessionId);
		}

		url.searchParams.set("utm_medium", "get-app");

		return url.toString();
	}

	get shareHref() {
		const {HOST_WEB} = this.$config;

		if (process.client && !window.URL) {
			return HOST_WEB;
		}

		const url = new URL("/", HOST_WEB);

		const {sessionAgent} = this;

		if (!sessionAgent) {
			return;
		}

		if (sessionAgent) {
			url.searchParams.set("presented-by", sessionAgent.oid);
		}

		return url.toString();
	}

	get canonicalHref() {
		const {HOST_WEB} = this.$config;

		if (process.client && !window.URL) {
			return HOST_WEB;
		}

		const url = new URL("/home", HOST_WEB);

		const {sessionAgent} = this;

		if (!sessionAgent) {
			return;
		}

		if (sessionAgent) {
			url.searchParams.set("presented-by", sessionAgent.oid);
		}

		return url.toString();
	}

	async shareApp(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();

		const {sessionAgent} = this;

		if (!sessionAgent) {
			return;
		}

		if ((navigator as any).share) {
			let title = "Listed. The app for people who love real estate. ";
			title += `Presented by ${sessionAgent!.name}`;

			const url = this.shareHref;

			try {
				await ((navigator as any).share as NavigatorShare)({title, url});
			} catch (e) {
				if (e instanceof Error && e.name === "AbortError") {
					// the user cancelled sharing
					(this as any).$matomo?.trackEvent("Agent link", "Share:Cancel", sessionAgent.oid);
				} else {
					this.$sentry?.captureException(e);
				}

				return;
			}

			(this as any).$matomo?.trackEvent("Agent link", "Share:Complete", sessionAgent.oid);

			this.$store.dispatch("recordGenericActorEvent", {
				type: ActorEventType.APP_SHARE,
			});
		}
	}

	mounted() {
		const {sessionAgent, performAppDownloadRedirect} = this;
		const query = this.$router.currentRoute.query;

		if (sessionAgent && query["presented-by"] !== sessionAgent.oid) {
			this.$router.replace({
				path: "/home",
				query: {
					...query,
					"presented-by": sessionAgent.oid
				}
			}).catch(() => {});
		}

		// since we can't detect iPads from UA string in SSR contexts, we have to check here
		// first, we determine that this is the entry page
		// then check that it's a Mac with touch capabilities (aka an iPad in a Mac-shaped trenchcoat)
		if (
			performAppDownloadRedirect &&
			!this.$nuxt.context.from &&
			navigator.platform &&
			(
				/iPad|iPhone|iPod/.test(navigator.platform) ||
				(/MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 2)
			)
		) {
			const baseDownloadURLAsURL = new URL(this.$config.APP_DOWNLOAD_LINK_IOS);

			baseDownloadURLAsURL.searchParams.set("pt", "118470456");
			baseDownloadURLAsURL.searchParams.set("mt", "8"); // unecessary, but we'll add it

			if (this.sessionAgent) {
				baseDownloadURLAsURL.searchParams.set("ct", this.sessionAgent.oid);
			}

			const downloadUrl = baseDownloadURLAsURL.toString();

			// Note: this won't register as an outlink in SSR contexts
			(this as any).$matomo?.trackLink(downloadUrl, "link");

			window.location.assign(downloadUrl);

			return;
		}
	}

	get agentSystemName(): string | null {
		const {sessionAgent} = this;

		const termsOfUseUri = sessionAgent?.vowTermsOfUseUri;

		if (termsOfUseUri) {
			const url = new URL(termsOfUseUri);
			const pathname = url.pathname;
			const boardSlug = pathname.slice(pathname.lastIndexOf("/") + 1).replace(/\.[a-z]*$/i, "");

			return boardSlug;
		}

		return null;
	}

	get isIOS(): boolean {
		return (
			(
				process.client &&
				(
					navigator.platform &&
					(
						/iPad|iPhone|iPod/.test(navigator.platform) ||
						(/MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 2)
					)
				)
			) ||
			(
				this.$ssrContext &&
				/iPad|iPhone|iPod/.test(this.$ssrContext.req.headers["user-agent"] || "")
			) ||
			// /CPU iPhone OS/i.test(navigator.userAgent) || // useful for testing
			false
		)
	}

	get isAndroid(): boolean {
		return (
			process.client && /Android/i.test(navigator.userAgent)
		) ||
		(
			this.$ssrContext &&
			/Android/i.test(this.$ssrContext.req.headers["user-agent"] || "")
		) ||
		false;
	}

	get isAppPlatform(): boolean {
		return this.isIOS || this.isAndroid;
	}

	get phoneOutputIsValid(): boolean {
		const {phoneOutput: phone} = this;

		if (!phone) {
			return false;
		}

		const maybePhoneNumber = parsePhoneNumberFromString(phone);

		return Boolean(maybePhoneNumber);
	}

	async onPreregister(target_url: URL) {
		const {phoneOutput: phone} = this;

		// do a simple check (as opposed to phoneOutputIsValid)
		if (!phone) {
			return;
		}

		try {
			await this.$apollo.mutate({
				mutation: PreregisterWithPhoneMutation,
				variables: {
					phoneNumber: phone,
				},
			});
		} catch (e) {
			this.$sentry?.captureException(e);
		}

		window.location.assign(target_url);
	}
}
